<template>
  <div>
    <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else>
      <div class="breadcrumb">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/overhaulTask' }">检修计划</el-breadcrumb-item>
          <el-breadcrumb-item>计划详情</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="form-box">
        <el-form v-if="form.baseInfo" :model="form" :rules="rules" ref="ruleForm" label-width="100px">
          <div class="form-title">基本信息</div>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="检修代号：">{{form.baseInfo.order}}</el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="来源：">{{form.baseInfo.source == 0 ? '新创建' : form.baseInfo.source == 1 ? '巡检' : '点检'}}</el-form-item>
            </el-col>
          </el-row>
          <div class="form-title">设备信息</div>
          <div>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="设备别名：">{{form.patrolDeviceInfo.alias}}</el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="设备识别号：">{{ form.patrolDeviceInfo.ein ? form.patrolDeviceInfo.ein : '无' }}</el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="设施位置：">{{form.patrolDeviceInfo.facilityName}}</el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="巡视点名称：">{{form.patrolDeviceInfo.patrolName}}</el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="设备名称：">{{form.patrolDeviceInfo.deviceName}}</el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="设备类型：">{{form.patrolDeviceInfo.deviceTypeName}}</el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="所属部门：">{{form.patrolDeviceInfo.departmentName}}</el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="规格参数：">{{form.patrolDeviceInfo.specification}}</el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="投入使用日期：" label-width="130px">
                  {{ form.patrolDeviceInfo.applyTime ? dayjs(form.patrolDeviceInfo.applyTime).format('YYYY-MM-DD'):'' }}
                </el-form-item>
              </el-col>
              <el-col :span="12"></el-col>
            </el-row>
          </div>

          <div v-if="form.baseInfo.source != 0">
            <div class="form-title">问题文字描述</div>
            <el-form-item label-width="20px">{{form.abnormalTaskInfo.describeContent}}</el-form-item>
            <div class="form-title">巡检工作图片</div>
            <div class="img-box">
              <el-image 
                class="images"
                v-for="(item,index) in form.abnormalTaskInfo.fileUrls"
                :key="index"
                :src="item"
                :preview-src-list="[item]">
              </el-image>
            </div>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="巡检人：">{{form.abnormalTaskInfo.submitter ? form.abnormalTaskInfo.submitter : '无'}}</el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="提交时间：">
                    {{ form.abnormalTaskInfo.completeTime ? dayjs(form.abnormalTaskInfo.completeTime).format('YYYY-MM-DD HH:mm:ss'):'-' }}
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          
          <div class="form-title">检修人员配置</div>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="检修人员">
                <div class="staffBox">
                  <div v-if="staffInfos.length>0">
                    <el-tag v-for="staff in staffInfos" :key="staff.reconditionStaffId" style="margin-right: 4px;">
                      {{ staff.staffName }}
                    </el-tag>
                  </div>
                  <div v-else style="color: #909399;text-align: center;">无检修人员</div>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="12"></el-col>
          </el-row>

          <div class="form-title">问题文字描述</div>
          <el-form-item label-width="20px">{{elementInfos.description}}</el-form-item>

          <div class="form-title">检修方案（审核人员填）</div>
          <el-form-item label-width="20px">{{elementInfos.plan}}</el-form-item>
        
          <div class="bottom-btn">
            <el-button type="primary" size="small" @click="$router.push('/overhaulTask')">取消</el-button>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
export default {
  data() {
    return {
      loading: false,
      active: 2,
      form: {
        abnormalTaskInfo: {},
        baseInfo: {},
        patrolDeviceInfo: {}
      },
      elementInfos: {},
      staffInfos: [],
      rules: {},
      row: {},
      auditOpin: ''
    }
  },
  methods: {
    dayjs,
    // 获取设备列表数据
    loadDynamicData() {
      this.loading = true
      this.$ajax.post("reconditionDynamicDetail", {
        reconditionId: this.row.reconditionId
      }).then((res) => {
        sessionStorage.setItem("detail", true);
        this.elementInfos = res.data.elementInfos[0]
        this.staffInfos = res.data.elementInfos[0].staffInfos
        this.form = res.data
        this.loading = false
      }).catch(err=>{
        this.loading = false
      })
    }
  },
  mounted() {
    if (this.$route.params.row == null) {
      this.$router.push("/overhaulTask");
      return;
    }
    this.row = this.$route.params.row;
    this.loadDynamicData()
  }
}
</script>

<style lang="less" scoped>
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.breadcrumb {
  padding: 15px 10px;
  .el-breadcrumb {
    font-size: 18px;
  }
}
.form-box {
  max-width: 1000px;
}
.staffBox {
  background: #fff;
  margin-right: 20px;
  height: 40px;
  border-radius: 4px;
  padding: 0 4px;
  border: 1px solid #e7e9ee;
}
.img-box {
  .images {
    overflow: hidden;
    background-color: #fff;
    border: 1px solid #c0ccda;
    border-radius: 6px;
    box-sizing: border-box;
    width: 148px;
    height: 148px;
    margin: 0 8px 8px 0;
    display: inline-block;
  }
}
</style>